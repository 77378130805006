<script setup lang="ts">
import { ArrowRightIcon } from 'lucide-vue-next';
import type { DefaultLayoutQuery } from '#gql';

defineProps<{ items: DefaultLayoutQuery['mainNavigation']['items'] }>();

const {
  isSecondLevelOpen,
  setIsBurgerNavigationVisible,
  setSecondLevel,
} = useBurgerNavigation();

const { getStoryblokUrl } = useStoryblokData();
</script>

<template>
  <div class="burger-menu-first-level relative -right-full flex flex-col bg-white">
    <span
      v-for="(item, index) in items"
      :key="item._uid"
      class="flex w-full cursor-pointer items-center justify-between border-b border-b-grey p-4"
      :class="{ 'border-none': isSecondLevelOpen }"
    >
      <NuxtLink
        v-if="!item.hasFlyoutItems"
        class="w-full text-sm"
        :to="getStoryblokUrl(item.link)"
        @click="setIsBurgerNavigationVisible(false)"
      >
        <span
          class="cursor-pointer"
          v-text="item.title"
        />
      </NuxtLink>
      <span
        v-else
        class="flex w-full items-center justify-between text-sm"
        @click="setSecondLevel(index)"
      >
        <span v-text="item.title" />
        <ArrowRightIcon
          v-if="item.hasFlyoutItems"
          class="size-4 text-dark"
        />
      </span>
    </span>
  </div>
</template>
